// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-docs-js": () => import("./../src/pages/docs.js" /* webpackChunkName: "component---src-pages-docs-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-notices-cancel-js": () => import("./../src/pages/notices/cancel.js" /* webpackChunkName: "component---src-pages-notices-cancel-js" */),
  "component---src-pages-notices-eula-js": () => import("./../src/pages/notices/eula.js" /* webpackChunkName: "component---src-pages-notices-eula-js" */),
  "component---src-pages-notices-privacy-js": () => import("./../src/pages/notices/privacy.js" /* webpackChunkName: "component---src-pages-notices-privacy-js" */)
}

